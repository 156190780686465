import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getSelectedOrganization } from './selectors';

const ROLE_KEY = `${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`;
export const ROLES = {
  admin: 'SmartSWM - Administrator',
  orgadmin: 'Organization Administrator',
};

export function getRoles(user) {
  return user[ROLE_KEY];
}

export function RoleContainer({
  role,
  children,
  user = false,
  organization = false,
}) {
  const currentUser = useSelector((state) => state.userInfo || {});
  const currentOrg = useSelector((state) => getSelectedOrganization(state));

  let userRoles = [];
  let adminOrgs = [];
  if (user) {
    userRoles = user.roles || [];
    adminOrgs = user.app_metadata?.sswm?.admin || [];
  } else {
    userRoles = currentUser.roles || [];
    adminOrgs = currentUser.app_metadata?.sswm?.admin || [];
  }

  if (user && organization) {
    if (adminOrgs.includes(organization.org_id)) {
      userRoles = userRoles.concat('Organization Administrator');
    }
  } else if (currentOrg) {
    if (adminOrgs.includes(currentOrg.org_id)) {
      userRoles = userRoles.concat('Organization Administrator');
    }
  }

  const hasRole = userRoles.includes(ROLES[role]);
  return hasRole ? children : null;
}

export function ProtectedRoute(props) {
  const { user, roles, children } = props;
  const userRoles = user[ROLE_KEY];
  const allowed =
    roles == null ||
    roles.filter((role) => userRoles.includes(role)).length > 0;
  return allowed ? (
    <Route {...props}>{children}</Route>
  ) : (
    <Redirect to={{ pathname: '/', state: { from: props.location } }} />
  );
}
