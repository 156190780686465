import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const PREFIX = 'Title';

const classes = {
  title: `${PREFIX}-title`,
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  [`&.${classes.title}`]: {},
}));

export default function Title({
  children,
  gutterBottom = true,
  color = 'textPrimary',
  ...restProps
}) {
  return (
    <StyledTypography
      variant="h6"
      color={color}
      gutterBottom={gutterBottom}
      className={classes.title}
      {...restProps}
    >
      {children}
    </StyledTypography>
  );
}

Title.propTypes = {
  children: PropTypes.node,
};
