export const getOrganizations = (state) => state.organizations.items;

export const getSelectedOrganization = (state) =>
  state.organizations.items.find((o) => o.selected);

export const getLastUpdate = (state, facility) => {
  if (state.updates && state.updates[facility.facilityId]) {
    return state.updates[facility.facilityId][0];
  }
  return {};
};
