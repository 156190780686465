import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import thunk from 'redux-thunk';
import { Facility, Site, AppState, Organization } from './types';

export const newState: AppState = {
  authenticating: true,
  isAuthenticated: false,
  authError: false,
  user: null,
  userInfo: null,
  organizations: {
    items: [],
    users: {},
    roles: [],
    invites: {},
    lastUpdated: null,
  },
  facilities: {
    isFetching: false,
    isEditing: false,
    editError: false,
    lastUpdated: null,
    items: [],
    metadata: {},
  },
  sites: [],
  updates: {},
  forecasts: {},
  shadows: {},
  loading: false,
  popupOpen: false,
  drawerOpen: false,
  dataWindow: {
    duration: 72,
    earliestTime: null,
    latestTime: null,
    startTime: null,
    endTime: null,
    startTimeFetched: null,
    endTimeFetched: null,
    updates: [],
    forecasts: [],
    wwes: [],
    historical: [],
    facilityId: null,
  },
  futureForecast: null,
  reports: {
    Items: [],
    loading: false,
  },
  actions: {
    Items: [],
    loading: false,
  },
  wwes: {
    Items: [],
    loading: false,
  },
  events: {
    Items: [],
    loading: false,
    lastSeen: 0,
  },
};

const rootReducer = (state = newState, action) => {
  switch (action.type) {
    case 'AUTHENTICATING':
      return {
        ...state,
        authenticating: true,
        authError: false,
      };
    case 'SET_AUTHENTICATED':
      return {
        ...state,
        authenticating: false,
        isAuthenticated: action.isAuthenticated,
        authError: false,
        user: action.user,
      };
    case 'AUTH_ERROR':
      return {
        ...state,
        authenticating: false,
        isAuthenticated: action.isAuthenticated,
        authError: action.authError,
      };
    case 'SET_USER':
      return {
        ...state,
        user: action.user,
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        userInfo: action.userInfo,
      };
    case 'SET_DRAWER_OPEN':
      return { ...state, drawerOpen: true };
    case 'SET_DRAWER_CLOSED':
      return { ...state, drawerOpen: false };
    case 'SET_LOADING':
      return { ...state, loading: action.loading };
    case 'SET_ORGANIZATION':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          items: state.organizations.items.concat(action.organization),
        },
      };
    case 'SET_ORGANIZATIONS':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          items: action.organizations,
        },
      };
    case 'CLEAR_ORGANIZATIONS':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          items: [],
        },
      };
    case 'SELECT_ORGANIZATION':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          items: state.organizations.items.map((organization: any) => ({
            ...organization,
            selected: organization.org_id === action.orgId,
          })),
        },
        // reset rest of state
        facilities: {
          ...state.facilities,
          items: [],
          metadata: {},
        },
        sites: [],
        updates: {},
        forecasts: {},
        shadows: {},
        dataWindow: {
          ...state.dataWindow,
          facilityId: null,
        },
        futureForecast: null,
        reports: {
          ...state.reports,
          Items: [],
        },
        actions: {
          ...state.actions,
          Items: [],
        },
        wwes: {
          ...state.wwes,
          Items: [],
        },
        events: {
          ...state.events,
          Items: [],
        },
      };
    case 'SET_FACILITIES':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          items: action.facilities,
          lastUpdated: Date.now(),
          isFetching: false,
        },
      };
    case 'SET_FACILITY_UPDATES':
      return {
        ...state,
        updates: {
          ...state.updates,
          [action.facility.facilityId]: action.updates,
        },
      };
    case 'SET_SITES':
      return {
        ...state,
        sites: action.sites,
      };
    case 'SET_UPDATES':
      return {
        ...state,
        updates: action.updates,
      };
    case 'CLEAR_SHADOWS':
      return {
        ...state,
        shadows: {},
      };
    case 'SET_SHADOWS':
      return {
        ...state,
        shadows: action.shadows,
      };
    case 'SET_SHADOW':
      return {
        ...state,
        shadows: {
          ...state.shadows,
          ...action.shadow,
        },
      };
    case 'SET_FORECASTS':
      return {
        ...state,
        forecasts: action.forecasts,
      };
    case 'WWES_FETCHSTART':
      return {
        ...state,
        wwes: {
          ...state.wwes,
          Items: [],
          loading: true,
        },
      };
    case 'WWES_FETCHSUCCESS':
      return {
        ...state,
        wwes: {
          Items: action.Items,
          LastEvaluatedKey: action.LastEvaluatedKey,
          page: action.page,
          loading: false,
        },
      };
    case 'SET_DATA_WINDOW':
      return {
        ...state,
        dataWindow: {
          ...state.dataWindow,
          ...action.dataWindow,
        },
      };
    case 'SET_DATA_WINDOW_DATA':
      return {
        ...state,
        dataWindow: {
          ...state.dataWindow,
          updates: action.updates,
          forecasts: action.forecasts,
          wwes: action.wwes,
        },
      };
    case 'SET_FUTURE_FORECASTS':
      return {
        ...state,
        futureForecast: action.futureForecast,
      };
    case 'REPORTS_FETCHSTART':
      return {
        ...state,
        reports: {
          ...state.reports,
          Items: [],
          loading: true,
        },
      };
    case 'REPORTS_FETCHSUCCESS':
      return {
        ...state,
        reports: {
          Items: action.Items,
          LastEvaluatedKey: action.LastEvaluatedKey,
          page: action.page,
          loading: false,
        },
      };
    case 'ACTIONS_FETCHSTART':
      return {
        ...state,
        actions: {
          ...state.actions,
          Items: [],
          loading: true,
        },
      };
    case 'ACTIONS_FETCHSUCCESS':
      return {
        ...state,
        actions: {
          loading: false,
          Items: action.Items,
          LastEvaluatedKey: action.LastEvaluatedKey,
          page: action.page,
        },
      };
    case 'EVENTS_FETCHSTART':
      return {
        ...state,
        events: {
          ...state.events,
          Items: [],
          loading: true,
        },
      };
    case 'EVENTS_FETCHSUCCESS':
      return {
        ...state,
        events: {
          ...state.events,
          Items: action.Items,
          LastEvaluatedKey: action.LastEvaluatedKey,
          page: action.page,
          loading: false,
        },
      };
    case 'EDIT_FACILITY_REQUEST':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          isEditing: true,
          editError: false,
        },
      };
    case 'EDIT_FACILITY_ERROR':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          isEditing: false,
          editError: action.error,
        },
      };
    case 'EDIT_FACILITY_SUCCESS':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          isEditing: false,
          editError: false,
          items: state.facilities.items.map((f: Facility) => {
            if (f.facilityId === action.facility.facilityId) {
              return action.facility;
            }
            return f;
          }),
        },
      };
    case 'UPDATE_FACILITY':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          items: state.facilities.items.map((f: Facility) => {
            if (f.facilityId === action.facility.facilityId) {
              return action.facility;
            }
            return f;
          }),
        },
      };
    case 'UPDATE_FACILITY_METADATA':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          metadata: {
            ...state.facilities.metadata,
            [action.facility.facilityId]: {
              ...(state.facilities.metadata &&
                state.facilities.metadata[action.facility.facilityId]),
              lastOpened: action.lastOpened,
            },
          },
        },
      };
    case 'EDIT_ORGANIZATION_SUCCESS':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          isEditing: false,
          editError: false,
          items: state.organizations.items.map((o: Organization) => {
            if (o.org_id === action.organization.org_id) {
              return {
                ...o,
                ...action.organization,
              };
            }
            return o;
          }),
        },
      };
    case 'EDIT_SITE_SUCCESS':
      return {
        ...state,
        sites: state.sites.map((s: Site) => {
          if (s.site_id === action.site.site_id) {
            return action.site;
          }
          return s;
        }),
      };
    case 'DELETE_SITE_SUCCESS':
      return {
        ...state,
        sites: state.sites.filter((s: Site) => {
          return s.site_id !== action.site.site_id;
        }),
      };
    case 'ADD_ORGANIZATION_SUCCESS':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          items: state.organizations.items.concat(action.organization),
        },
      };
    case 'DELETE_ORGANIZATION_SUCCESS':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          items: state.organizations.items.filter(
            (o: Organization) => o.org_id !== action.organization.org_id
          ),
        },
      };
    case 'ADD_SITE_SUCCESS':
      return {
        ...state,
        sites: state.sites.concat(action.site),
      };
    case 'ADD_FACILITY_SUCCESS':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          items: state.facilities.items.concat(action.facility),
        },
      };
    case 'DELETE_FACILITY_SUCCESS':
      return {
        ...state,
        facilities: {
          ...state.facilities,
          items: state.facilities.items.filter((f: Facility) => {
            return f.facilityId !== action.facility.facilityId;
          }),
        },
      };
    case 'SET_ORGANIZATION_USERS':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          users: {
            ...state.organizations.users,
            [action.orgId]: action.users,
          },
          roles: {
            ...state.organizations.roles,
            [action.orgId]: action.roles,
          },
        },
      };
    case 'SET_ORGANIZATION_INVITES':
      return {
        ...state,
        organizations: {
          ...state.organizations,
          invites: {
            ...state.organizations.invites,
            [action.orgId]: action.invites,
          },
        },
      };
    case 'SET_LAST_SEEN_EVENT':
      return {
        ...state,
        events: {
          ...state.events,
          lastSeen: action.lastSeen,
        },
      };
    default:
      return state;
  }
};

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const migrations = {
  1: (state) => {
    return {
      ...state,
      reports: {
        loading: false,
        Items: state.reports,
      },
    };
  },
};

const persistConfig = {
  key: 'root',
  storage,
  version: 1,
  migrate: createMigrate(migrations, { debug: false }),
  blacklist: ['authenticating'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export { store, persistor };
