import { useEffect, useMemo, lazy, Suspense } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Container, CssBaseline } from '@mui/material';
import { Pond, Updates } from '@smartswm/sswm-core';
import routes from './routes';
import { loadHomeData, initAuth0 } from './actions';
import { getSelectedOrganization } from './selectors';
import { ScrollToTop, usePrevious } from './hooks';
import Loader from './Loader';
import NavBar from './NavBar';
import Drawer from './Drawer';
import './App.css';
const PREFIX = 'App';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: 'flex',
    width: '100%',
  },

  [`& .${classes.content}`]: {
    width: '100%',
    maxWidth: 1140,
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.container}`]: {
    flex: 1,
    position: 'relative',
    padding: theme.spacing(2),
  },

  [`& .${classes.title}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },

  [`& .${classes.fixedHeight}`]: {
    height: 270,
  },

  [`& .${classes.toolbar}`]: theme.mixins.toolbar,
}));

const Home = lazy(() => import('./Home'));
const Login = lazy(() => import('./Login'));

function App() {
  const dispatch = useDispatch();
  const authenticating = useSelector((state) => state.authenticating);
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const organization = useSelector(getSelectedOrganization);
  const facilities = useSelector((state) => state.facilities.items);
  const sites = useSelector((state) => state.sites);
  const updates = useSelector((state) => state.updates);
  const user = useSelector((state) => state.user);
  const prevOrg = usePrevious(organization);

  useEffect(() => {
    dispatch(
      initAuth0({
        domain: process.env.REACT_APP_AUTH0_OPENID_DOMAIN,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
      })
    );
  }, [dispatch]);

  // load home data after org is switched or loaded
  useEffect(() => {
    if (
      isAuthenticated &&
      organization &&
      !authenticating &&
      (!prevOrg || prevOrg.org_id !== organization.org_id)
    ) {
      dispatch(loadHomeData(organization));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organization]);

  // load home data after authenticationg
  useEffect(() => {
    if (isAuthenticated && organization && !authenticating) {
      dispatch(loadHomeData(organization));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticating]);

  const ponds = useMemo(() => {
    if (Object.keys(updates).length === 0) {
      return [];
    }
    return facilities.map((f) => {
      const pond = new Pond(f, { preloadRegimes: false });
      if (updates && updates[f.facilityId]) {
        var u = new Updates(updates[f.facilityId]);
        pond.updates = u;
      }
      return pond;
    });
  }, [facilities, updates]);

  if (!isAuthenticated && authenticating) {
    return <Loader />;
  }

  const routeProps = { user, ponds, sites };

  return (
    <Root className={classes.root}>
      <CssBaseline />
      {isAuthenticated ? (
        <BrowserRouter>
          <ScrollToTop />
          <NavBar />
          <Drawer {...routeProps} />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Suspense fallback={<div></div>}>
              <Container maxWidth={false} className={classes.container}>
                <Switch>
                  {routes.map((route) => {
                    return (
                      <Route
                        exact={route.exact}
                        path={route.path}
                        key={route.path}
                        render={() => <route.component {...routeProps} />}
                      ></Route>
                    );
                  })}
                  <Route>
                    <Home {...routeProps} />
                  </Route>
                  <Redirect from="/reference" to="/docs/reference" />
                </Switch>
              </Container>
            </Suspense>
          </main>
        </BrowserRouter>
      ) : (
        <Suspense fallback={<div></div>}>
          <Login />
        </Suspense>
      )}
    </Root>
  );
}

export default App;
