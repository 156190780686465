import { lazy } from 'react';
import { ROLES } from './roles';
const ActionLog = lazy(() => import('./ActionLog'));
const FacilityStatus = lazy(() => import('./FacilityStatus'));
const FacilityActivity = lazy(() => import('./FacilityActivity'));
const FacilityCharacteristics = lazy(() => import('./FacilityCharacteristics'));
const FacilityWeather = lazy(() => import('./FacilityWeather'));
const FacilityPower = lazy(() => import('./FacilityPower'));
const FacilityMap = lazy(() => import('./FacilityMap'));
const FacilityDiagnostics = lazy(() => import('./FacilityDiagnostics'));
const Map = lazy(() => import('./Map'));
const Reports = lazy(() => import('./Reports'));
const Report = lazy(() => import('./Report'));
const EditFacility = lazy(() => import('./EditFacility'));
const EditFacilityHardware = lazy(() => import('./EditFacilityHardware'));
const EditModes = lazy(() => import('./EditModes'));
const EditDischarge = lazy(() => import('./EditDischarge'));
const EditStageStorage = lazy(() => import('./EditStageStorage'));
const EditValvePerformance = lazy(() => import('./EditValvePerformance'));
const OverrideControl = lazy(() => import('./OverrideControl'));
const FacilityEvents = lazy(() => import('./FacilityEvents'));
const Events = lazy(() => import('./Events'));
const Docs = lazy(() => import('./Docs'));
const DocsNewUser = lazy(() => import('./DocsNewUser'));
const DocsStatus = lazy(() => import('./DocsStatus'));
const DocsReference = lazy(() => import('./DocsReference'));
const DocsModes = lazy(() => import('./DocsModes'));
const DocsActivity = lazy(() => import('./DocsActivity'));
const DocsCharacteristics = lazy(() => import('./DocsCharacteristics'));
const DocsWeather = lazy(() => import('./DocsWeather'));
const Home = lazy(() => import('./Home'));
const Facility = lazy(() => import('./Facility'));
const Sites = lazy(() => import('./Sites'));
const Site = lazy(() => import('./Site'));
const Organization = lazy(() => import('./Organization'));
const EditOrganization = lazy(() => import('./EditOrganization'));
const AddOrganization = lazy(() => import('./AddOrganization'));
const User = lazy(() => import('./User'));
const Admin = lazy(() => import('./Admin'));
const Simulation = lazy(() => import('./Simulation'));
const AddFacility = lazy(() => import('./AddFacility'));
const AddSite = lazy(() => import('./AddSite'));
const EditSite = lazy(() => import('./EditSite'));

const routes = [
  {
    path: '/',
    title: 'System',
    exact: true,
    component: Home,
  },
  {
    path: '/sites',
    title: 'Sites',
    exact: true,
    component: Sites,
  },
  {
    path: '/facility/:facilityId',
    title: 'Facility',
    exact: false,
    component: Facility,
    routes: [
      {
        path: '/facility/:facilityId',
        title: 'Status',
        tab: true,
        component: FacilityStatus,
      },
      {
        path: '/facility/:facilityId/activity',
        title: 'Activity',
        tab: true,
        component: FacilityActivity,
        dataWindow: true,
      },
      {
        path: '/facility/:facilityId/characteristics',
        title: 'Characteristics',
        tab: true,
        component: FacilityCharacteristics,
      },
      {
        path: '/facility/:facilityId/weather',
        title: 'Weather',
        tab: true,
        component: FacilityWeather,
      },
      {
        path: '/facility/:facilityId/power',
        title: 'Power',
        tab: true,
        component: FacilityPower,
        dataWindow: true,
      },
      {
        path: '/facility/:facilityId/events',
        title: 'Events',
        tab: true,
        component: FacilityEvents,
      },
      {
        path: '/facility/:facilityId/map',
        title: 'Map',
        tab: true,
        component: FacilityMap,
      },
      {
        path: '/facility/:facilityId/simulation',
        title: 'Simulation',
        tab: true,
        component: Simulation,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/edit',
        title: 'Edit Facility',
        component: EditFacility,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/edit-hardware',
        title: 'Edit Facility Hardware',
        component: EditFacilityHardware,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/edit-modes',
        title: 'Edit Modes',
        component: EditModes,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/edit-discharge',
        title: 'Edit Discharge',
        component: EditDischarge,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/edit-stage-storage',
        title: 'Edit Stage Storage',
        component: EditStageStorage,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/edit-valve-performance',
        title: 'Edit Valve Performance',
        component: EditValvePerformance,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/override',
        title: 'Override Control',
        component: OverrideControl,
        roles: [ROLES.admin],
      },
      {
        path: '/facility/:facilityId/diagnostics',
        title: 'Diagnostics',
        component: FacilityDiagnostics,
        roles: [ROLES.admin],
      },
    ],
  },
  {
    path: '/map',
    title: 'Map',
    exact: true,
    component: Map,
  },
  {
    path: '/reports',
    title: 'Reports',
    component: Reports,
    routes: [
      {
        path: '/reports/:orgId/:month/:year',
        title: 'Report',
        component: Report,
      },
    ],
  },
  {
    path: '/actions',
    title: 'Action Log',
    exact: true,
    component: ActionLog,
  },

  {
    path: '/organization/:orgId',
    title: 'Organization',
    exact: true,
    component: Organization,
  },
  {
    path: '/user/:userId',
    title: 'User',
    exact: true,
    component: User,
  },
  {
    path: '/events',
    title: 'Recent Events',
    exact: true,
    component: Events,
  },
  {
    path: '/docs',
    title: 'Documentation',
    component: Docs,
    routes: [
      {
        path: '/docs/new-user',
        title: 'Users and Organizations',
        component: DocsNewUser,
      },
      {
        path: '/docs/facility-status',
        title: 'Facility Status',
        component: DocsStatus,
      },
      {
        path: '/docs/facility-activity',
        title: 'Facility Activity',
        component: DocsActivity,
      },
      {
        path: '/docs/facility-characteristics',
        title: 'Facility Characteristics',
        component: DocsCharacteristics,
      },
      {
        path: '/docs/facility-weather',
        title: 'Facility Weather',
        component: DocsWeather,
      },
      {
        path: '/docs/modes',
        title: 'Operational Modes',
        component: DocsModes,
      },
      {
        path: '/docs/reference',
        title: 'Reference',
        component: DocsReference,
      },
    ],
  },
  {
    path: '/administrator',
    title: 'Administrator',
    exact: true,
    component: Admin,
    roles: [ROLES.admin],
    routes: [
      {
        path: '/actions',
        title: 'Action Log',
        exact: true,
        component: ActionLog,
        roles: [ROLES.admin],
      },
    ],
  },
  {
    path: '/organizations/add',
    title: 'Add Organization',
    exact: true,
    component: AddOrganization,
    roles: [ROLES.admin],
  },
  {
    path: '/organization/:orgId/settings',
    title: 'Organization Settings',
    exact: true,
    component: EditOrganization,
    roles: [ROLES.admin],
  },
  {
    path: '/facilities/add',
    title: 'Add Facility',
    exact: true,
    component: AddFacility,
    roles: [ROLES.admin],
  },
  {
    path: '/sites/add',
    title: 'Add Site',
    exact: true,
    component: AddSite,
    roles: [ROLES.admin],
  },
  {
    path: '/site/:siteId/edit',
    title: 'Edit Site',
    exact: true,
    component: EditSite,
    roles: [ROLES.admin],
  },
  {
    path: '/site/:siteId',
    title: 'Site',
    exact: true,
    component: Site,
  },
];

export default routes;
