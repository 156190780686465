const Loader = () => {
  return (
    <div className={'App-logo'}>
      <svg
        width="263"
        height="198"
        viewBox="0 0 263 298"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="131.995" cy="5.66848" r="5.66848" fill="#FF0000" />
        <circle cx="131.995" cy="110.94" r="5.66848" fill="#84BD00" />
        <circle cx="131.995" cy="187.06" r="5.66848" fill="#84BD00" />
        <circle
          cx="92.4849"
          cy="188.51"
          r="5.66848"
          transform="rotate(-135 92.4849 188.51)"
          fill="#84BD00"
        />
        <circle
          cx="171.504"
          cy="109.49"
          r="5.66848"
          transform="rotate(-135 171.504 109.49)"
          fill="#84BD00"
        />
        <circle
          cx="76.1196"
          cy="149"
          r="5.66848"
          transform="rotate(-90 76.1196 149)"
          fill="#84BD00"
        />
        <circle
          cx="187.87"
          cy="149"
          r="5.66848"
          transform="rotate(-90 187.87 149)"
          fill="#84BD00"
        />
        <circle
          cx="92.4849"
          cy="109.49"
          r="5.66848"
          transform="rotate(-45 92.4849 109.49)"
          fill="#84BD00"
        />
        <circle
          cx="171.504"
          cy="188.51"
          r="5.66848"
          transform="rotate(-45 171.504 188.51)"
          fill="#84BD00"
        />
        <circle cx="131.995" cy="93.125" r="5.66848" fill="#84BD00" />
        <circle cx="131.995" cy="204.875" r="5.66848" fill="#84BD00" />
        <circle
          cx="107.345"
          cy="216.724"
          r="5.66848"
          transform="rotate(-160 107.345 216.724)"
          fill="#84BD00"
        />
        <circle
          cx="156.644"
          cy="81.2758"
          r="5.66848"
          transform="rotate(-160 156.644 81.2758)"
          fill="#84BD00"
        />
        <circle
          cx="85.6684"
          cy="204.209"
          r="5.66848"
          transform="rotate(-140 85.6684 204.209)"
          fill="#84BD00"
        />
        <circle
          cx="178.321"
          cy="93.7907"
          r="5.66848"
          transform="rotate(-140 178.321 93.7907)"
          fill="#84BD00"
        />
        <circle
          cx="69.5796"
          cy="185.035"
          r="5.66848"
          transform="rotate(-120 69.5796 185.035)"
          fill="#84BD00"
        />
        <circle
          cx="194.41"
          cy="112.965"
          r="5.66848"
          transform="rotate(-120 194.41 112.965)"
          fill="#84BD00"
        />
        <circle
          cx="61.0188"
          cy="161.515"
          r="5.66848"
          transform="rotate(-100 61.0188 161.515)"
          fill="#84BD00"
        />
        <circle
          cx="202.97"
          cy="136.485"
          r="5.66848"
          transform="rotate(-100 202.97 136.485)"
          fill="#84BD00"
        />
        <circle
          cx="61.0188"
          cy="136.485"
          r="5.66848"
          transform="rotate(-80 61.0188 136.485)"
          fill="#84BD00"
        />
        <circle
          cx="202.97"
          cy="161.515"
          r="5.66848"
          transform="rotate(-80 202.97 161.515)"
          fill="#84BD00"
        />
        <circle
          cx="69.5796"
          cy="112.965"
          r="5.66848"
          transform="rotate(-60 69.5796 112.965)"
          fill="#84BD00"
        />
        <circle
          cx="194.41"
          cy="185.035"
          r="5.66848"
          transform="rotate(-60 194.41 185.035)"
          fill="#84BD00"
        />
        <circle
          cx="85.6684"
          cy="93.7907"
          r="5.66848"
          transform="rotate(-40 85.6684 93.7907)"
          fill="#84BD00"
        />
        <circle
          cx="178.321"
          cy="204.209"
          r="5.66848"
          transform="rotate(-40 178.321 204.209)"
          fill="#84BD00"
        />
        <circle
          cx="107.345"
          cy="81.2757"
          r="5.66848"
          transform="rotate(-20 107.345 81.2757)"
          fill="#84BD00"
        />
        <circle
          cx="156.644"
          cy="216.724"
          r="5.66848"
          transform="rotate(-20 156.644 216.724)"
          fill="#84BD00"
        />
        <circle cx="131.995" cy="76.9293" r="5.66848" fill="#84BD00" />
        <circle cx="131.995" cy="221.071" r="5.66848" fill="#84BD00" />
        <circle
          cx="114.459"
          cy="237.159"
          r="5.66848"
          transform="rotate(-168.75 114.459 237.159)"
          fill="#0092BC"
        />
        <circle
          cx="149.53"
          cy="60.8412"
          r="5.66848"
          transform="rotate(-168.75 149.53 60.8412)"
          fill="#0092BC"
        />
        <circle
          cx="97.5967"
          cy="232.044"
          r="5.66848"
          transform="rotate(-157.5 97.5967 232.044)"
          fill="#0092BC"
        />
        <circle
          cx="166.392"
          cy="65.9563"
          r="5.66848"
          transform="rotate(-157.5 166.392 65.9563)"
          fill="#0092BC"
        />
        <circle
          cx="82.0566"
          cy="223.737"
          r="5.66848"
          transform="rotate(-146.25 82.0566 223.737)"
          fill="#0092BC"
        />
        <circle
          cx="181.932"
          cy="74.2627"
          r="5.66848"
          transform="rotate(-146.25 181.932 74.2627)"
          fill="#0092BC"
        />
        <circle
          cx="68.4356"
          cy="212.559"
          r="5.66848"
          transform="rotate(-135 68.4356 212.559)"
          fill="#0092BC"
        />
        <circle
          cx="195.553"
          cy="85.4411"
          r="5.66848"
          transform="rotate(-135 195.553 85.4411)"
          fill="#0092BC"
        />
        <circle
          cx="57.2571"
          cy="198.938"
          r="5.66848"
          transform="rotate(-123.75 57.2571 198.938)"
          fill="#0092BC"
        />
        <circle
          cx="206.732"
          cy="99.0621"
          r="5.66848"
          transform="rotate(-123.75 206.732 99.0621)"
          fill="#0092BC"
        />
        <circle
          cx="48.9509"
          cy="183.398"
          r="5.66848"
          transform="rotate(-112.5 48.9509 183.398)"
          fill="#0092BC"
        />
        <circle
          cx="215.038"
          cy="114.602"
          r="5.66848"
          transform="rotate(-112.5 215.038 114.602)"
          fill="#0092BC"
        />
        <circle
          cx="43.8358"
          cy="166.536"
          r="5.66848"
          transform="rotate(-101.25 43.8358 166.536)"
          fill="#0092BC"
        />
        <circle
          cx="220.153"
          cy="131.464"
          r="5.66848"
          transform="rotate(-101.25 220.153 131.464)"
          fill="#0092BC"
        />
        <circle
          cx="42.1087"
          cy="149"
          r="5.66848"
          transform="rotate(-90 42.1087 149)"
          fill="#0092BC"
        />
        <circle
          cx="221.88"
          cy="149"
          r="5.66848"
          transform="rotate(-90 221.88 149)"
          fill="#0092BC"
        />
        <circle
          cx="43.8359"
          cy="131.464"
          r="5.66848"
          transform="rotate(-78.75 43.8359 131.464)"
          fill="#0092BC"
        />
        <circle
          cx="220.153"
          cy="166.536"
          r="5.66848"
          transform="rotate(-78.75 220.153 166.536)"
          fill="#0092BC"
        />
        <circle
          cx="48.9508"
          cy="114.602"
          r="5.66848"
          transform="rotate(-67.5 48.9508 114.602)"
          fill="#0092BC"
        />
        <circle
          cx="215.038"
          cy="183.398"
          r="5.66848"
          transform="rotate(-67.5 215.038 183.398)"
          fill="#0092BC"
        />
        <circle
          cx="57.2572"
          cy="99.062"
          r="5.66848"
          transform="rotate(-56.25 57.2572 99.062)"
          fill="#0092BC"
        />
        <circle
          cx="206.732"
          cy="198.938"
          r="5.66848"
          transform="rotate(-56.25 206.732 198.938)"
          fill="#0092BC"
        />
        <circle
          cx="68.4356"
          cy="85.441"
          r="5.66848"
          transform="rotate(-45 68.4356 85.441)"
          fill="#0092BC"
        />
        <circle
          cx="195.553"
          cy="212.559"
          r="5.66848"
          transform="rotate(-45 195.553 212.559)"
          fill="#0092BC"
        />
        <circle
          cx="82.0567"
          cy="74.2627"
          r="5.66848"
          transform="rotate(-33.75 82.0567 74.2627)"
          fill="#0092BC"
        />
        <circle
          cx="181.933"
          cy="223.737"
          r="5.66848"
          transform="rotate(-33.75 181.933 223.737)"
          fill="#0092BC"
        />
        <circle
          cx="97.5967"
          cy="65.9563"
          r="5.66848"
          transform="rotate(-22.5 97.5967 65.9563)"
          fill="#0092BC"
        />
        <circle
          cx="166.392"
          cy="232.044"
          r="5.66848"
          transform="rotate(-22.5 166.392 232.044)"
          fill="#0092BC"
        />
        <circle
          cx="114.459"
          cy="60.8412"
          r="5.66848"
          transform="rotate(-11.25 114.459 60.8412)"
          fill="#0092BC"
        />
        <circle
          cx="149.53"
          cy="237.159"
          r="5.66848"
          transform="rotate(-11.25 149.53 237.159)"
          fill="#0092BC"
        />
        <circle cx="131.995" cy="59.1141" r="5.66848" fill="#0092BC" />
        <circle cx="131.995" cy="238.886" r="5.66848" fill="#0092BC" />
        <circle
          cx="219.128"
          cy="237.753"
          r="5.66848"
          transform="rotate(-45 219.128 237.753)"
          fill="#003B71"
        />
        <circle cx="5.66851" cy="149.81" r="5.66848" fill="#003B71" />
        <circle cx="256.701" cy="149.81" r="5.66848" fill="#003B71" />
        <circle
          cx="155.856"
          cy="43.9753"
          r="5.66848"
          transform="rotate(12.8 155.856 43.9753)"
          fill="#003B71"
        />
        <circle
          cx="177.851"
          cy="51.5492"
          r="5.66848"
          transform="rotate(25.2 177.851 51.5492)"
          fill="#003B71"
        />
        <circle
          cx="197.708"
          cy="63.6696"
          r="5.66848"
          transform="rotate(37.6 197.708 63.6696)"
          fill="#003B71"
        />
        <circle
          cx="214.498"
          cy="79.7711"
          r="5.66848"
          transform="rotate(50 214.498 79.7711)"
          fill="#003B71"
        />
        <circle
          cx="227.44"
          cy="99.1026"
          r="5.66848"
          transform="rotate(62.4 227.44 99.1026)"
          fill="#003B71"
        />
        <circle
          cx="235.928"
          cy="120.762"
          r="5.66848"
          transform="rotate(74.8 235.928 120.762)"
          fill="#003B71"
        />
        <circle
          cx="239.567"
          cy="143.739"
          r="5.66848"
          transform="rotate(87.2 239.567 143.739)"
          fill="#003B71"
        />
        <circle
          cx="238.187"
          cy="166.961"
          r="5.66848"
          transform="rotate(99.6 238.187 166.961)"
          fill="#003B71"
        />
        <circle
          cx="231.853"
          cy="189.346"
          r="5.66848"
          transform="rotate(112 231.853 189.346)"
          fill="#003B71"
        />
        <circle
          cx="220.86"
          cy="209.848"
          r="5.66848"
          transform="rotate(124.4 220.86 209.848)"
          fill="#003B71"
        />
        <circle
          cx="205.721"
          cy="227.511"
          r="5.66848"
          transform="rotate(136.8 205.721 227.511)"
          fill="#003B71"
        />
        <circle
          cx="187.142"
          cy="241.511"
          r="5.66848"
          transform="rotate(149.2 187.142 241.511)"
          fill="#003B71"
        />
        <circle
          cx="165.99"
          cy="251.195"
          r="5.66848"
          transform="rotate(161.6 165.99 251.195)"
          fill="#003B71"
        />
        <circle
          cx="143.252"
          cy="256.111"
          r="5.66848"
          transform="rotate(174 143.252 256.111)"
          fill="#003B71"
        />
        <g opacity="0.6">
          <circle
            cx="119.989"
            cy="256.03"
            r="5.66848"
            transform="rotate(-173.6 119.989 256.03)"
            fill="#003B71"
          />
        </g>
        <circle
          cx="97.2861"
          cy="250.955"
          r="5.66848"
          transform="rotate(-161.2 97.2861 250.955)"
          fill="#003B71"
        />
        <circle
          cx="76.2025"
          cy="241.124"
          r="5.66848"
          transform="rotate(-148.8 76.2025 241.124)"
          fill="#003B71"
        />
        <circle
          cx="57.7218"
          cy="226.994"
          r="5.66848"
          transform="rotate(-136.4 57.7218 226.994)"
          fill="#003B71"
        />
        <circle
          cx="42.7063"
          cy="209.226"
          r="5.66848"
          transform="rotate(-124 42.7063 209.226)"
          fill="#003B71"
        />
        <circle
          cx="31.8567"
          cy="188.647"
          r="5.66848"
          transform="rotate(-111.6 31.8567 188.647)"
          fill="#003B71"
        />
        <circle
          cx="25.6789"
          cy="166.219"
          r="5.66848"
          transform="rotate(-99.2 25.6789 166.219)"
          fill="#003B71"
        />
        <circle
          cx="24.4614"
          cy="142.988"
          r="5.66848"
          transform="rotate(-86.8 24.4614 142.988)"
          fill="#003B71"
        />
        <circle
          cx="28.261"
          cy="120.037"
          r="5.66848"
          transform="rotate(-74.4 28.261 120.037)"
          fill="#003B71"
        />
        <circle
          cx="36.9002"
          cy="98.4373"
          r="5.66848"
          transform="rotate(-62 36.9002 98.4373)"
          fill="#003B71"
        />
        <circle
          cx="49.9761"
          cy="79.1968"
          r="5.66848"
          transform="rotate(-49.6 49.9761 79.1968)"
          fill="#003B71"
        />
        <circle
          cx="66.8787"
          cy="63.2128"
          r="5.66848"
          transform="rotate(-37.2 66.8787 63.2128)"
          fill="#003B71"
        />
        <circle
          cx="86.8192"
          cy="51.2314"
          r="5.66848"
          transform="rotate(-24.8 86.8192 51.2314)"
          fill="#003B71"
        />
        <circle
          cx="108.867"
          cy="43.8113"
          r="5.66848"
          transform="rotate(-12.4 108.867 43.8113)"
          fill="#003B71"
        />
        <circle cx="131.995" cy="41.299" r="5.66848" fill="#003B71" />
        <circle
          cx="119.989"
          cy="256.03"
          r="5.66848"
          transform="rotate(-173.6 119.989 256.03)"
          fill="#003B71"
        />
        <circle cx="131.995" cy="274.516" r="5.66848" fill="#003B71" />
        <circle cx="131.995" cy="292.332" r="5.66848" fill="#003B71" />
        <circle cx="131.995" cy="5.66848" r="5.66848" fill="#003B71" />
        <circle cx="131.995" cy="23.4837" r="5.66848" fill="#003B71" />
        <circle
          cx="41.6215"
          cy="60.2466"
          r="5.66848"
          transform="rotate(-45 41.6215 60.2466)"
          fill="#003B71"
        />
        <circle
          cx="43.2411"
          cy="237.753"
          r="5.66848"
          transform="rotate(45 43.2411 237.753)"
          fill="#003B71"
        />
        <circle
          cx="220.748"
          cy="60.2465"
          r="5.66848"
          transform="rotate(45 220.748 60.2465)"
          fill="#003B71"
        />
      </svg>
    </div>
  );
};
export default Loader;
