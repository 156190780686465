import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import { respondInvite } from './actions';

const PREFIX = 'Invitation';

const classes = {
  invite: `${PREFIX}-invite`,
  buttons: `${PREFIX}-buttons`,
  root: `${PREFIX}-root`,
  bullet: `${PREFIX}-bullet`,
  title: `${PREFIX}-title`,
  pos: `${PREFIX}-pos`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.invite}`]: {
    display: 'flex',
    flexDirection: 'column',
  },

  [`& .${classes.buttons}`]: {
    paddingTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
  },

  [`&.${classes.root}`]: {
    maxWidth: 275,
    marginBottom: theme.spacing(1),
    whiteSpace: 'normal',
  },

  [`& .${classes.bullet}`]: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },

  [`& .${classes.title}`]: {
    fontSize: 14,
  },

  [`& .${classes.pos}`]: {
    marginBottom: 12,
  },
}));

export default function Invitation({ invite }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  async function handleAccept() {
    setLoading(true);
    await dispatch(respondInvite(invite, true));
    setLoading(false);
  }

  async function handleDismiss() {
    setLoading(true);
    await dispatch(respondInvite(invite, false));
    setLoading(false);
  }

  return (
    <StyledCard className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Organization Invite
        </Typography>
        <Typography variant="body2" component="p">
          You have been invited to join the <b>{invite.org_id}</b> organization.
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleDismiss} disabled={loading}>
          Dismiss
        </Button>
        <Button color="primary" onClick={handleAccept} disabled={loading}>
          Accept
        </Button>
      </CardActions>
    </StyledCard>
  );
}
