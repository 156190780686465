import { createTheme, adaptV4Theme } from '@mui/material/styles';

var retaining = '#81BD00';
var issue = '#ed561b';
var dewatering = '#dddf00';
var draining = '#dddf00';

export default createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#003C71',
      },
      secondary: {
        main: '#81BD00',
        contrastText: '#fff',
      },
      white: {
        main: '#fff',
        dark: '#fff',
        light: '#fff',
        contrastText: '#fff',
      },
      orange: {
        main: '#b1740f',
      },
      brown: {
        main: '#b2aa8e',
      },
      application: {
        blue: {
          100: 'hsl(190,100%,92%)',
          200: 'hsl(200,100%,85%)',
          300: 'hsl(208,100%,80%)',
          400: 'hsl(208,100%,70%)',
          500: 'hsl(208,100%,40%)',
          600: 'hsl(208,100%,30%)',
          700: 'hsl(208,100%,22%)',
          800: 'hsl(215,100%,18%)',
          900: 'hsl(230,100%,12%)',
        },
        secondaryBlue: {
          100: '#e3f9ff',
          200: '#95e7ff',
          300: '#5adaff',
          400: '#1fcdff',
          500: '#00b0e3',
          600: '#0092bc',
          700: '#007495',
          800: '#00556e',
          900: '#003746',
        },
        green: {
          100: 'hsl(75,100%,95%)', //'#f1ffd1',
          200: 'hsl(75,100%,74%)', //'#dfff96',
          300: 'hsl(75,100%,64%)', //'#ceff5b',
          400: 'hsl(75,100%,54%)', //'#b6ff0c',
          500: 'hsl(78,100%,44%)', //'#9fe400',
          600: 'hsl(85,100%,34%)', //'#84bd00',
          700: 'hsl(95,100%,24%)', //'#699600',
          800: 'hsl(100,100%,14%)', //'#4d6f00',
          900: 'hsl(110,100%,6%)', //'#324700',
        },
        orange: {
          100: '#f9deb3',
          200: '#f4c77d',
          300: '#f1b758',
          400: '#eea834',
          500: '#d58c12',
          600: '#b1740f',
          700: '#8d5c0c',
          800: '#694509',
          900: '#452d06',
        },
        gray: {
          100: '#fafafa',
          200: '#F5F6F7',
          300: '#eeeeee',
          400: '#e0e0e0',
          500: '#bdbdbd',
          600: '#9e9e9e',
          700: '#757575',
          800: '#616161',
          900: '#424242',
        },
        grayblue: {
          100: 'hsl(208, 21%, 94%)',
          200: 'hsl(208, 18%, 92%)',
          300: 'hsl(208, 15%, 85%)',
          400: 'hsl(208, 12%, 75%)',
          500: 'hsl(208, 12%, 58%)',
          600: 'hsl(208, 15%, 40%)',
          700: 'hsl(208, 17%, 30%)',
          800: 'hsl(208, 21%, 20%)',
          900: 'hsl(208, 20%, 15%)',
        },
      },
      status: {
        retaining,
        issue,
        dewatering,
        draining,
      },
      text: {
        primary: 'hsl(208, 20%, 15%)',
        secondary: 'hsl(208, 15%, 40%)',
      },
    },
    typography: {
      body1: {
        fontFamily: 'futura-pt',
      },
      body2: {
        fontFamily: 'futura-pt',
      },
      h1: {
        fontFamily: 'futura-pt',
        fontWeight: 'bold',
      },
      h2: {
        fontFamily: 'futura-pt',
        fontWeight: 'bold',
      },
      h3: {
        fontFamily: 'futura-pt',
        fontWeight: 'bold',
      },
      h4: {
        fontFamily: 'futura-pt',
        fontWeight: 'bold',
      },
      h5: {
        fontFamily: 'futura-pt',
        fontWeight: 'bold',
      },
      h6: {
        fontFamily: 'futura-pt',
        fontWeight: 500,
        letterSpacing: '-0.03em',
      },
      caption: {
        color: 'hsl(208, 15%, 40%)',
      },
      fontFamily: [
        'futura-pt',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
      ].join(','),
    },
  })
);

/*
Futura PT Medium
font-family: futura-pt, sans-serif;
font-weight: 500;
font-style: normal;
Futura PT Medium Oblique
font-family: futura-pt, sans-serif;
font-weight: 500;
font-style: italic;
Futura PT Heavy
font-family: futura-pt, sans-serif;
font-weight: 700;
font-style: normal;
Futura PT Heavy Oblique
font-family: futura-pt, sans-serif;
font-weight: 700;
font-style: italic;
Futura PT Light Oblique
font-family: futura-pt, sans-serif;
font-weight: 300;
font-style: italic;
Futura PT Book
font-family: futura-pt, sans-serif;
font-weight: 400;
font-style: normal;
Futura PT Book Oblique
font-family: futura-pt, sans-serif;
font-weight: 400;
font-style: italic;
Futura PT Light
font-family: futura-pt, sans-serif;
font-weight: 300;
font-style: normal;
Futura PT Extra Bold
font-family: futura-pt, sans-serif;
font-weight: 800;
font-style: normal;
Futura PT Extra Bold Oblique
font-family: futura-pt, sans-serif;
font-weight: 800;
font-style: italic;
Futura PT Demi
font-family: futura-pt, sans-serif;
font-weight: 600;
font-style: normal;
Futura PT Demi Oblique
font-family: futura-pt, sans-serif;
font-weight: 600;
font-style: italic;
Futura PT Bold
font-family: futura-pt-bold, sans-serif;
font-weight: 700;
font-style: normal;
Futura PT Bold Oblique
font-family: futura-pt-bold, sans-serif;
font-weight: 700;
font-style: italic;
Futura PT Cond Book
font-family: futura-pt-condensed, sans-serif;
font-weight: 400;
font-style: normal;
Futura PT Cond Book Oblique
font-family: futura-pt-condensed, sans-serif;
font-weight: 400;
font-style: italic;
Futura PT Cond Medium
font-family: futura-pt-condensed, sans-serif;
font-weight: 500;
font-style: normal;
Futura PT Cond Medium Oblique
font-family: futura-pt-condensed, sans-serif;
font-weight: 500;
font-style: italic;
Futura PT Cond Bold
font-family: futura-pt-condensed, sans-serif;
font-weight: 700;
font-style: normal;
Futura PT Cond Bold Oblique
font-family: futura-pt-condensed, sans-serif;
font-weight: 700;
font-style: italic;
Futura PT Cond Extra Bold
font-family: futura-pt-condensed, sans-serif;
font-weight: 800;
font-style: normal;
Futura PT Cond Extra Bold Oblique
font-family: futura-pt-condensed, sans-serif;
font-weight: 800;
font-style: italic;
*/
